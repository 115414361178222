.about-container {
    font-family: 'DM Sans', sans-serif;
  transition: all 350ms ease-out;
  width: 100vw;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.photo-jason {
    padding: 20px 0 10px 0;
    max-width: 200px;
}

// .photo {

// }

.header {
  font-style: italic;
  font-size: 32px;
  position: relative;
  bottom: 5%;
//   animation-delay: 0.25s;
  letter-spacing: 2px;
  text-align: center;
}

.slide-top {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 0.25s;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(50);
    transform: translateY(50);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 100;
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(50);
    transform: translateY(50);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 100;
  }
}

.fade-in {
  -webkit-animation: fade-in 1.1s cubic-bezier(0.645, 0.045, 0.355, 1) both;
  animation: fade-in 1.1s cubic-bezier(0.645, 0.045, 0.355, 1) both;
  animation-delay: .25s;
}

.fade-in-btn { 
    -webkit-animation: fade-in 1.4s cubic-bezier(0.645, 0.045, 0.355, 1) both;
    animation: fade-in 1.4s cubic-bezier(0.645, 0.045, 0.355, 1) both;
    animation-delay: .6s;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.about-bg {
  width: 100vw;
  height: 80vh;
  background-color: #dff0ea;
  position: absolute;
  z-index: -1;
  clip-path: polygon(0 30%, 100% 15%, 100% 83%, 0 97%);
}

.bg--animate {
  -webkit-animation: bg-animate 500ms cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: bg-animate 500ms cubic-bezier(0.65, 0, 0.35, 1) both;
  animation-delay: 0.25s;
}

@-webkit-keyframes bg-animate {
  0% {
    // clip-path: polygon(0 20%, 100% 20%, 70% 70%, 0 100%);
  }
  100% {
    clip-path: polygon(0 30%, 100% 15%, 100% 83%, 0 97%);
  }
}

@keyframes bg-animate {
  0% {
    // clip-path: polygon(0 20%, 100% 20%, 70% 70%, 0 100%);
  }
  100% {
    clip-path: polygon(0 30%, 100% 15%, 100% 83%, 0 97%);
  }
}

.about {
  display: flex;
  flex-direction: row;
  // background-color: pink;
  width: 100%;
  // margin-top: 40px;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text {
  padding: 25px 35px 0 35px;
  line-height: 30px;
  font-size: 18px;
  h2 { 
    font-weight: bold;
    font-size: 22px;
  }
}

.text-2 {
  padding: 10px 35px 0 35px;
  line-height: 25px;
}

.explore {
  padding: 15px 0 0 0;
}

.explore__btn {
  display: block;
  position: relative;
  padding: 4px 8px 4px 8px;
  border-radius: 0%;
  border-width: thin;
  background: none;
  text-transform: lowercase;
  letter-spacing: 1px;
  font-size: 17px;
  outline: 0;
  overflow: hidden;
}

.fill:hover {
  color: whitesmoke;
  cursor: pointer;
}

.fill:before {
  content: "";
  position: absolute;
  background: black;
  bottom: 0;
  left: 0;
  right: 0;
  top: 100%;
  z-index: -1;
  transition: top 0.6s ease;
}

.fill:hover:before {
  top: 0;
}

.back__btn {
  position: absolute;
  left: 35px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .about {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {...}

/* Medium devices (landscape tablets, 768px and up) */
// @media only screen and (min-width: 768px) {...}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .about {
    width: 73%;
  }
  .photo-jason  {
    max-width: 300px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .about {
    width: 70%;
    // width: 1000px;
    // height: 700px;
  }
  @keyframes bg-animate {
    0% {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
    100% {
      clip-path: polygon(0 41%, 100% 28%, 100% 59%, 0 73%);
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(15);
      transform: translateY(15);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(-60px);
      transform: translateY(-60px);
      opacity: 100;
    }
  }
  
  .explore__btn {
    font-size: 17px;
    padding: 5px 10px 5px 10px;
  }
}
