.form-control { 
    position: relative;
    z-index: 0;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contact-title {
    text-align: center;
}

.contact-subhead {
    text-align: center;
    padding: 30px 0;
}

.form-group {
    text-align: left;
    padding: 10px 0;
}

.form-label { 
    padding-bottom: 15px;
}

.text-center { 
    text-align: center;
    padding: 15px 0;  
    button {
        padding: 5px 10px;
    }
}
