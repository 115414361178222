input {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.container { 
    width: 100vw;
    min-height: 50vh;
    display: flex; 
    justify-content: center;
    align-items: center;
    overflow:hidden;
}

.accordion { 
    width: inherit;
    display: flex; 
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.section {
    font-style: italic;
    font-weight: bold;
    font-family: 'DM Sans', sans-serif;
    padding: 15px 0;
}
.tab-label { 
    transition: all .37s ease-in-out;
    font-family: 'DM Sans', sans-serif;
    font-weight: bold;
    font-size: 52px;
    cursor: pointer;
    color: black;
    -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    -webkit-background-clip: text;
    overflow: hidden;
    background-image: linear-gradient(180deg, rgba(102,163,228,1) 0%, rgba(0,212,255,1) 100%);       
    background-clip: text;
    background-position: 0px 70px;
    background-repeat: no-repeat;
    &-personal { 
        background-image: linear-gradient(180deg, rgb(84, 60, 192) 0%, rgb(129, 103, 248) 100%);       
    }
    &:hover { 
        background-position: 0px 0px;
    }
}

.tabs { 
    width: 100vh;
}

.tab {
    width: inherit;
    overflow: hidden;
}

.tab-content {
    font-family: 'DM Sans', sans-serif;
    letter-spacing: .5px;
    font-size: 15px;
    line-height: 20px;
    opacity: 0;
    max-height: 0;
    max-width: 800px;
    padding: 0 1em;
    margin-top: 10px;
    color: black;
    background: white;
    transition: all ease-in-out .6s;
}

.tab-img { 
    max-width: 500px;
}

.company { 
    font-style: italic;
    font-size: 12px;
}

.tech {
    font-weight: bold;
    font-size: 14px;
}

.project-links { 
    display: flex;
    flex-direction: row;
    .project-link { 
        text-decoration: none;
        display:inline-block;
        padding:0.5em 2.5em;
        border:0.16em solid black;
        margin:0 0.3em 0.3em 0;
        box-sizing: border-box;
        text-transform:uppercase;
        font-family:'Roboto',sans-serif;
        font-weight:400;
        color:black;
        text-align:center;
        transition: all 0.15s;
        &:hover {
            color:rgba(0,212,255,1);
            border-color:rgba(0,212,255,1);
        }
    }
    .project-link-personal { 
        text-decoration: none;
        display:inline-block;
        padding:0.5em 2.5em;
        border:0.16em solid black;
        margin:0 0.3em 0.3em 0;
        box-sizing: border-box;
        text-transform:uppercase;
        font-family:'Roboto',sans-serif;
        font-weight:400;
        color:black;
        text-align:center;
        transition: all 0.15s;
        &:hover {
            color:rgb(129, 103, 248);
            border-color:rgb(129, 103, 248);
        }
    }
}

ul .task { 
    padding: 2px 0;
    &::before { 
        content: "\2192 \0020";
    }
}

input:checked {
    + .tab-label {
        background-position: 0 0px;
    }

    ~ .tab-content  {
        opacity: 1;
        display: block;
        max-height: 110vh;
        padding: 1em;
    }
}
  
.slide-right {
	-webkit-animation: slide-right 500ms cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    animation: slide-right 500ms cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    overflow: hidden;
}


@-webkit-keyframes slide-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(100px);
        transform: translateX(100px);
        opacity: 1;
    }
}

@keyframes slide-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(100px);
        transform: translateX(100px);
        opacity: 1;
    }
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .tab-label {
        font-size: 28px;
    }
    .tab-content {
        max-width: 275px;
        img { 
            max-width: 260px;
        }
    }

    .project-links {
        flex-direction: column;
        .project-link {
        display: block;
        margin: 0.4em auto;
        }
    }
    
    input:checked {
        ~ .tab-content  {
            opacity: 1;
            display: block;
            max-height: 140vh;
            padding: 1em;
        }
    }

    @-webkit-keyframes slide-right {
        0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(250px);
            transform: translateX(250px);
            opacity: 1;
        }
    }

    @keyframes slide-right {
        0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(250px);
            transform: translateX(250px);
            opacity: 1;

        }
    }
}
  
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .tab-label {
        font-size: 38px;
    }
    .tab-content {
        max-width: 435px;
        img { 
            max-width: 425px;
        }
    }
    @-webkit-keyframes slide-right {
        0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(360px);
            transform: translateX(360px);
            opacity: 1;
        }
    }
    @keyframes slide-right {
        0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(360px);
            transform: translateX(360px);
            opacity: 1;

        }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .tab-label {
        font-size: 46px;
    }
    .tab-content {
        max-width: 575px;
    }
    @-webkit-keyframes slide-right {
        0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(275px);
            transform: translateX(275px);
            opacity: 1;
        }
    }
    
    @keyframes slide-right {
        0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(275px);
            transform: translateX(275px);
            opacity: 1;
        }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .tab-label {
        font-size: 52px;
    }
    @-webkit-keyframes slide-right {
        0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(100px);
            transform: translateX(100px);
            opacity: 1;
        }
    }
    
    @keyframes slide-right {
        0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(100px);
            transform: translateX(100px);
            opacity: 1;
        }
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}