* {
  margin: 0;
  padding: 0;
}

.header {
  font-style: italic;
  font-size: 32px;
  position: relative;
  //   animation-delay: 0.25s;
  letter-spacing: 2px;
}

.nav-container { 
    font-family: 'DM Sans', sans-serif;
    transition: all 350ms ease-out;
    width: 100vw;
    padding: 25px 0;
    min-height: 15vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.slide-top {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 0.25s;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 100;
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 100;
  }
}

.link {
    font-weight: bold;
    font-style: italic;
    cursor: pointer;
    text-transform: lowercase;
}

.nav-fill { 
    background: none;
    padding: 3px 15px;
    position: relative;
    color: black;
    &:before { 
        content: "";
        background: black;
        clip-path: polygon(11% 23%, 11% 23%, 2% 80%, 2% 80%);
        position: absolute;
        left: 0;
        top: 100%;
        right: 0;
        bottom: 0;
        z-index: -1;
    }
    &:hover {
        &:before {
            top: 0;
            -webkit-animation: nav-animate .35s cubic-bezier(.41,.01,.58,1) both;
            animation: nav-animate .35s cubic-bezier(.41,.01,.58,1) both;
        }
        color: white;
    }
}

.nav-filled {
    &:before { 
        content: "";
        background: black;
        clip-path: polygon(11% 16%, 100% 16%, 93% 87%, 2% 87%);
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }
    color: white;
}

@-webkit-keyframes nav-animate {
    0% {
        clip-path: polygon(11% 23%, 11% 23%, 2% 80%, 2% 80%);
    }
    100% {
        clip-path: polygon(11% 16%, 100% 16%, 93% 87%, 2% 87%);
    }
}
  
@keyframes nav-animate {
    0% {
        clip-path: polygon(11% 23%, 11% 23%, 2% 80%, 2% 80%);
    }
    100% {
        clip-path: polygon(11% 16%, 100% 16%, 93% 87%, 2% 87%);
    }
}

.nav-list { 
    width: 100vw;
    justify-content: center;
    display: flex;
    padding-top: 20px;
}




/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .nav-list { 
        width: 100px;
        flex-direction: column;
    }
    .nav-list a { 
        margin: 7px 0;
    }
    .nav-fill {
        text-align: center;
        padding: 0 
    }
    
}

/* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {...}

/* Medium devices (landscape tablets, 768px and up) */
// @media only screen and (min-width: 768px) {...}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}
